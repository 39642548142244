import { motion } from 'framer-motion';

export const LoupsPage = () => {
  const cards = [
    {
      name: 'Le Berger',
      wins_as: 'Villageois',
      description:
        "La première nuit, lors du tour des loups-garous, le berger lève son pouce (sans ouvrir les yeux) pour se faire reconnaître. Il est immunisé à l'attaque des loups : ceux-ci doivent donc le tuer pendant la journée. Toutes les 3 nuits (à partir de la 3ème nuit), le berger se réveille et désigne un joueur. Le maître du jeu lui indique si le joueur fait partie du camp des loups. Le berger recommence jusqu'à tomber sur un loup ou jusqu'à ce qu'il ait désigné 3 joueurs.",
      power: 'Protection / Voyance d’aura',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups garous',
      url: 'https://ldusuwqksjxyltpbwxfm.supabase.co/storage/v1/object/public/images/Le Berger.jpg'
    },
    {
      name: 'Le Chaperon rouge',
      wins_as: 'Villageois',
      description: 'Elle ne peut pas mourir tant que le chasseur est en vie.',
      power: 'Protection',
      team: 'Equipe',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: 'Le Chasseur',
      wins_as: 'Villageois',
      description: "Dès qu'il meurt, il peut désigner une personne qui mourra également",
      power: 'Pouvoir de tuer une personne désignée avant de mourir',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups-garous'
    },
    {
      name: 'Le Châtelain',
      wins_as: 'Groupe',
      description:
        "Le châtelain peut gracier n'importe quel condamné par le vote du village (y compris lui) à n'importe quel moment de la partie. Il ne peut utiliser son pouvoir qu'une fois dans la partie.",
      power: 'Protection',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: "L'Enfant Sauvage",
      wins_as: 'Gagne en groupe',
      description:
        "Au début de la partie, l'enfant sauvage choisit un mentor. Ce joueur ignore qu'il est le mentor de l'enfant sauvage. Si, au cours de la partie, le mentor vient à mourir, alors l'enfant sauvage devient un loup-garou.",
      power: 'Double jeu / Mort',
      team: 'Villageois / Loup Garou',
      goal: 'Rester en vie et éliminer les loups garous ou s’il devient loup garou, éliminer les villageois.'
    },
    {
      name: "L'Espion",
      wins_as: 'Groupe',
      description:
        "L'espion est du côté des villageois. Il a accès à l'antre des loups garous, peut discuter avec eux, voter avec eux (bien que ce vote soit fictif) et doit trouver un moyen d'éliminer les loups garous sans passer pour un suspect. Il n'a pas le droit de dénoncer directement les loups garous et doit éviter tout soupçon par rapport à son rôle ! Si, leur tour venant, les loups garous soupçonnent qu'il y a un espion parmi eux, ils peuvent voter contre le suspect. Si celui-ci est un parfait loup garou, il n'y aura pas de victime et les loups auront perdu un tour, mais s'il s'agit de l'espion, il sera dévoré.",
      power: 'Double Jeu',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups garous.'
    },
    {
      name: 'La Famille',
      wins_as: 'En groupe',
      description:
        'Ce sont des Villageois. Au début de la partie, le MJ les appelle à se réveiller. Ils se reconnaissent et peuvent donc se faire confiance tout au long de la partie.',
      power: 'Voyance',
      team: 'La Villageois',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: 'Le Fils de la lune',
      wins_as: 'Gagne en groupe',
      description:
        "Lors de la mort du fils de la lune, la lune est tellement triste qu'elle ne se lèvera pas la nuit suivante. Empêchant les loups garous de prendre forme. Tous les autres rôles pourront jouer leur pouvoir durant cette nuit sans lune.",
      power: 'Protection',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups garous.'
    },
    {
      name: 'Le Garou',
      wins_as: 'Groupe',
      description:
        'Lorsque quelqu’un est condamné par le vote du village, le Garou peut performer une chanson pour tenter de sauver la victime condamnée à mort.',
      power: 'Protection',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: "L'Hirondelle",
      wins_as: 'groupe',
      description:
        'Toutes les deux nuits, l’hirondelle désignera un joueur. Le jour venu, au moment du vote, la voix de ce joueur comptera double pour le vote du jour.',
      power: 'Protection',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: "L'Hypnotiseur",
      wins_as: 'En groupe',
      description:
        "L'Hypnotiseur se réveille toutes les nuits et désigne un premier joueur qui sera hypnotisé puis un deuxième joueur référent. Lorsque l’Hypnotiseur s’endort, le joueur hypnotisé se réveille et le MJ lui désigne son référent. Le joueur hypnotisé devra alors voter au jour suivant de la même manière que son référent. Attention : L’hypnotiseur ne peut hypnotiser chaque joueur qu’une fois dans la partie. Si l’un des trois concernés par l’hypnose meurt (Hypnotiseur, hypnotisé ou référent), l’effet de l’hypnose s’annule.",
      power: 'Contrôle',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: "Le Montreur d'ours",
      wins_as: 'Gagne en groupe',
      description:
        "Chaque matin, si le montreur d'ours se trouve à droite ou à gauche d'un membre du camp des loups-garous, son ours grogne (le MJ).",
      power: 'Voyance d’aura',
      team: 'Camp : Villageois',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: 'Le Noctambule',
      wins_as: 'Villageois',
      description:
        'Chaque nuit, il choisit une personne chez qui aller dormir. Cette personne connaîtra l’identité du noctambule mais sera privée de ses pouvoirs pour la nuit. Le noctambule ne peut pas dormir chez la même personne deux nuits de suite.',
      power: 'Contrôle',
      team: 'Equipe',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: 'La Petite Fille',
      wins_as: 'Villageois',
      description:
        'Lorsque les loups garous sont appelés la nuit, elle a le droit de les espionner sans se faire repérer.',
      power: 'Voyance',
      team: 'Equipe',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: 'Le Renard',
      wins_as: 'Villageois',
      description:
        'La première nuit, le renard flaire 3 personnes. Le Meneur lui dit si un membre du camp des loups-garous est dans ce groupe. Si oui, il pourra réutiliser son pouvoir la nuit suivante. Sinon, il devient simple villageois.',
      power: 'Voyance d’aura',
      team: 'Equipe',
      goal: 'Rester en vie et éliminer les loups garous.'
    },
    {
      name: 'Le Salvateur',
      wins_as: 'Groupe',
      description:
        'Chaque nuit, le salvateur protège une personne. Cette personne sera protégée et ne pourra donc pas mourir durant la nuit. Le salvateur ne peut pas protéger la même personne deux nuits de suite.',
      power: 'Protection',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: 'Le Séducteur',
      wins_as: 'groupe',
      description:
        'Chaque nuit, Le Séducteur désigne une personne avec laquelle il passe la nuit. Le MJ touche la tête de la personne séduite, qui se réveille. Si Le Séducteur meurt durant cette même nuit, la personne avec qui il a passé la nuit meurt également. Si Le Séducteur séduit un Loup Garou, lui et les autres loups-garous ne pourront pas manger cette nuit. Si le Séducteur séduit la Nonne (qui n’a pas encore utilisé son pouvoir), celle-ci meurt immédiatement de désespoir.',
      power: 'Contrôle',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: 'Le Serveur',
      wins_as: 'Villageois',
      description:
        'Chaque nuit, il peut offrir un verre à un joueur qui ne pourra pas voter le lendemain. Il ne peut pas offrir un verre à la même personne deux nuits de suite.',
      power: 'Contrôle',
      team: 'Equipe',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: 'La Sorcière',
      wins_as: 'Villageois',
      description:
        "La sorcière possède deux potions : une de guérison et une d'empoisonnement. Elle ne peut utiliser chacune de ses potions qu'une seule fois au cours de la partie.",
      power: 'Protection / Mort',
      team: 'Equipe',
      goal: 'Rester en vie et éliminer les loups garous.'
    },
    {
      name: 'La Survivante',
      wins_as: 'Groupe',
      description:
        'La survivante fera gagner les villageois si elle est la dernière en vie face au loup-garous (même s’ils sont en nombre supérieur). Tant que la survivante est en vie, les loup-garous n’ont pas gagné.',
      power: 'Contrôle',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups garous.'
    },
    {
      name: 'Le Villageois',
      wins_as: 'Groupe',
      description:
        "Le villageois est un personnage qui incarne l'habitant basique d'un village. Son rôle est de découvrir l'identité des loups-garous et de les éliminer avant qu'ils ne tuent tous les villageois.",
      power: 'Aucun',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups-garous.'
    },
    {
      name: "Voyante d'aura",
      wins_as: 'Groupe',
      description:
        "Chaque nuit, la Voyante d'aura peut désigner une personne et connaître son aura : 'Saine' du camp des villageois, 'Neutre' joueur neutre, 'Sombre' du camp des loups-garous.",
      power: 'Voyance d’aura',
      team: 'Villageois',
      goal: 'Rester en vie et éliminer les loups garous'
    },
    {
      name: "Zut, l'esprit joueur",
      wins_as: 'Jamais',
      description:
        'La première personne tuée par les loups-garous devient Zut, l’esprit joueur, compagnon spirituel de Pif. Zut est un fantôme errant dans le village, il peut participer au jeu mais ne peut pas voter. Il peut quitter la partie quand il le souhaite.',
      power: 'Aucun',
      team: 'Equipe villageois',
      goal: 'Aider les villageois'
    },
    {
      name: "L'Araignée",
      wins_as: 'groupe',
      description:
        "La première nuit, l'araignée désignera une personne qui sera prise dans sa toile. Les autres nuits, elle pourra étendre sa toile à deux autres personnes placées à côté de sa toile. Soit une à gauche et une à droite, soit deux à gauche ou deux à droite. Une nuit, lorsqu’elle le voudra, elle pourra tuer une personne, à condition que celle ci soit déjà prise dans sa toile.",
      power: 'Mort',
      team: 'Loups-Garous',
      goal: 'Rester en vie et tuer tous les villageois'
    },
    {
      name: 'Devin maléfique',
      wins_as: 'groupe',
      description:
        'Chaque nuit, le devin maléfique peut demander à connaître l’aura d’une nouvelle personne. Si celle-ci s’avère être du camp des loups-garous, le devin maléfique pourra, par le pouvoir des astres, maudire une autre personne qui mourra cette nuit.',
      power: 'Voyance / Mort',
      team: 'Loups-Garous',
      goal: 'Rester en vie et tuer tous les villageois'
    },
    {
      name: "L'Enfant sauvage",
      wins_as: 'Groupe',
      description:
        "Au début de la partie, l'enfant sauvage choisit un mentor. Ce joueur ignore qu'il est le mentor de l'enfant sauvage. Si, au cours de la partie, le mentor vient à mourir, alors l'enfant sauvage devient un loup-garou.",
      power: 'Double jeu / Mort',
      team: 'Villageois / Loup Garou',
      goal: 'Rester en vie et éliminer les loups garous ou s’il devient loup garou, éliminer les villageois.'
    },
    {
      name: 'Loup-Garou',
      wins_as: 'Groupe',
      description:
        'Il se réunit chaque nuit avec les autres loups-garous pour décider de leur victime. Si au cours de la partie, le camp des loups-garous est en majorité face aux autres joueurs, les loups-garous gagnent.',
      power: 'Mort',
      team: 'Loups-Garous',
      goal: 'Rester en vie et tuer tous les villageois'
    },
    {
      name: 'Loup Bleu',
      wins_as: 'Loups-Garous',
      description:
        "Il possède le pouvoir d'un loup garou normal, mais en début de partie, le MJ change sa carte avec une simple carte de villageois. De ce fait, si le Loup bleu est sondé par des pouvoirs de voyance, il sera vu/perçu comme un villageois.",
      power: 'Mort',
      team: 'Loups-Garous',
      goal: 'Rester en vie et tuer tous les villageois'
    },
    {
      name: 'Traitre',
      wins_as: 'Seul',
      description:
        "A la mort du dernier Loup-garou, le traître se réveille à l'appel des loups garous et tue une personne par nuit.",
      power: 'Double jeu / Mort',
      team: 'Villageois / Loups-Garous',
      goal: 'Rester en vie et éliminer les loups garous. Quand tous les loups sont morts, tuer tous les autres.'
    },
    {
      name: "L'Avocate",
      wins_as: 'Seul',
      description:
        "En début de partie, elle choisit deux clients. Ceux-ci ne sont pas au courant qu'ils sont ses clients. L'avocate gagne si l'un de ses deux clients reste en vie à la fin de la partie.",
      power: 'Protection',
      team: 'Neutre',
      goal: 'Rester en vie et garder l’un de ses deux clients en vie en fin de partie.'
    },
    {
      name: 'Cupidon',
      wins_as: 'Seul',
      description:
        "Cupidon est désormais un rôle neutre. La première nuit du jeu, il choisit deux joueurs à mettre en couple, puis il regarde leurs rôles. Cupidon devra protéger coûte que coûte cet amour et il ne gagnera la partie que s'ils survivent. Cupidon ferme les yeux, les amoureux se réveillent et à leur tour, ils se montrent leur rôle. Dès lors, les amoureux seront liés à la vie à la mort. Si l’un meurt, l’autre aussi. Mais si Cupidon meurt, le charme se brisera et ils seront enfin libres.",
      power: 'Voyance / Contrôle',
      team: 'Neutre',
      goal: 'Rester en vie, lui ainsi que ses amoureux.'
    },
    {
      name: 'Le Joueur de flûte',
      wins_as: 'Seul',
      description:
        'Le joueur de flûte se réveille toutes les nuits. Il charme alors un ou deux joueurs (en fonction de l’avancement de la partie sous l’autorité du MJ) qui deviendront les charmés.',
      power: 'Contrôle',
      team: 'Neutre',
      goal: 'Rester en vie et charmer tous les joueurs.'
    },
    {
      name: 'Le Lapin Blanc',
      wins_as: 'Seul',
      description:
        'Le lapin blanc se réveille toute les nuits. Il donne alors à un ou deux joueurs un chocolat. Il gagne lorsque tous les joueurs en vie ont reçu un chocolat. Si lors de la partie un joueur mange le chocolat qui lui a été offert, le lapin blanc aura le droit de donner un chocolat supplémentaire la nuit suivante.',
      power: 'Contrôle',
      team: 'Neutre',
      goal: 'Rester en vie et donner un chocolat à tous les joueurs en vie'
    },
    {
      name: 'La Nonne',
      wins_as: 'Inconnue',
      description:
        'Lorsque quelqu’un meurt, le meneur de jeu attend quelques secondes avant de dévoiler la carte de la victime, laissant ainsi la nonne le choix de s’en saisir et de continuer la partie avec cette nouvelle identité.',
      power: 'Contrôle',
      team: 'Inconnue',
      goal: 'A déterminer selon la carte qu’elle récupère.'
    },
    {
      name: "Pif, l'esprit farceur",
      wins_as: 'Neutre',
      description:
        'La personne éliminée par le village le premier jour devient Pif, un esprit farceur, neutre, muet, emmerdeur et compagnon spirituel de Zut. Toutes les nuits, à tour de rôle, il pourra désigner l’un de ses bourreaux. Ce dernier, hanté, n’aura pas le droit de parler le jour venu. S’il parle, celui-ci ne pourra pas voter. Pif ne peut pas hanter plus d’une fois le même bourreau. Une fois que chacun de ses bourreaux à été hanté, son rôle s’arrête. A noter que même si sa tâche n’est pas terminée, Pif, comme Zut, peut quitter définitivement la partie quand il le souhaite. Après tout, c’est un fantôme.',
      power: 'Contrôle',
      team: 'Neutre',
      goal: 'Emmerder'
    }
  ];

  return (
    <motion.div
      className="hitster-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}>
      <h2>Loups-Garous</h2>

      <section className="hitster-team-info">
        <h3>Rappel des rôles</h3>
        <div className="hitster-team-members-container">
          {cards?.map((card) => (
            <div className="loup-garou-card" key={card.name}>
              <img
                src={`https://storage.googleapis.com/aenigma-images/${encodeURI(
                  card.name.normalize('NFD')
                )}.jpg`}
                alt=""
              />
            </div>
          ))}
        </div>
      </section>
    </motion.div>
  );
};
