import { useRanking } from '../api/ranking';
import { useLocalStorage } from 'react-use';
import { motion } from 'framer-motion';
import { Loader } from './loader';

const rankToEmoji = (rank: number) => {
  if (rank === 1) return '🥇';
  if (rank === 2) return '🥈';
  if (rank === 3) return '🥉';
  return rank;
};

export const RankingPage = () => {
  const [userId] = useLocalStorage('userId', -1);
  const { data } = useRanking();
  let diff = [1];
  if (data) {
    for (let i = 1; i < data.length; i++) diff.push(data[i].rank - data[i - 1].rank);
  }

  return data ? (
    <motion.div
      className="ranking-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}>
      <div className="ranking-page-header">
        <p className="ranking-section-title">Classement général 🏆</p>
      </div>
      {data.map((player, i) => (
        <div
          key={player.player_id}
          className={
            userId === player.player_id
              ? 'scoreboard-row-current-player'
              : 'scoreboard-row-other-player'
          }>
          <span className="scoreboard-row-rank">
            {diff[i] === 1 ? rankToEmoji(player.rank) : '-'}
          </span>
          <span className="scoreboard-row-player-name">{player.player_name}</span>
          <span className="scoreboard-row-score">{player.total_score} pts</span>
        </div>
      ))}
      {/* <div className="button-container">
        <p onClick={() => navigate('/home')}>Retour au planning</p>
      </div> */}
    </motion.div>
  ) : (
    <Loader />
  );
};
