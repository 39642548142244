import { useState } from 'react';
import { useLocalStorage } from 'react-use';
import { ListUser } from '../components/list-user';
import { Scores } from './scores';
// let navigate = useNavigate();
export const ProtectedRoute = () => {
  const [userId, setUserId] = useLocalStorage('userId', -1);
  const [route] = useState('planning');

  if (typeof userId === 'number' && userId > -1) {
    return (
      <div className="planning-page">
        {route === 'planning' && <Scores userId={userId} />}
        {/* <div className="button-container">
          <button
            className={route === 'ranking' ? 'active' : 'inactive'}
            onClick={() => navigate('/ranking')}>
            Classement
          </button>
          <button
            className={route === 'planning' ? 'active' : 'inactive'}
            onClick={() => setRoute('planning')}>
            Planning
          </button>
          <button onClick={remove}>Déconnexion</button>
          {userId === 9 ? <button onClick={() => navigate('/admin')}>Back Office</button> : <></>}
        </div> */}
      </div>
    );
  }
  return <ListUser setUserId={setUserId} />;
};
