import React from 'react';

const capitalizeFirstLetter = (name: string | null, firstWord?: string) => {
  if (name) {
    const nameWithCap = name.charAt(0).toUpperCase() + name.toLowerCase().slice(1);
    if (firstWord) {
      return (firstWord + ' ' + nameWithCap).trim();
    }
    return nameWithCap;
  }
  return '';
};

export const User = ({
  name,
  id,
  setUserId,
  admin,
  winnerYear
}: {
  admin?: boolean;
  name: string;
  id: number;
  setUserId: any;
  winnerYear?: string;
}) => {
  const handleUserClick = () => {
    if (admin) {
      let mdp = prompt('Mot de passe ?', '');
      if (mdp === 'supper') {
        setUserId(id);
      }
    } else {
      setUserId(id);
    }
    setTimeout(() => {
      window.dispatchEvent(new Event('storage'));
    }, 100);
  };
  return (
    <div onClick={handleUserClick} className={`user ${winnerYear === '2023' ? 'gold' : ''}`}>
      {capitalizeFirstLetter(name)}
      {admin ? <p>Chef de table</p> : null}
      {winnerYear ? <p>🏆 {winnerYear}</p> : null}
    </div>
  );
};
