import { usePlanning } from '../api/planning';
import { useScores } from '../api/scores';
import { useUser } from '../api/users';
import { useNavigate } from 'react-router-dom';
import { useRanking } from '../api/ranking';
import { motion } from 'framer-motion';
import { Loader } from '../components/loader';

const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

const transformDate = (date: string, dateEnd: string) => {
  try {
    const d = new Date(date);
    const min = d.getMinutes() === 0 ? '00' : d.getMinutes();
    const dend = new Date(dateEnd);
    const minend = dend.getMinutes() === 0 ? '00' : dend.getMinutes();

    if (!dateEnd) {
      return days[d.getDay()] + ' - ' + d.getHours() + 'h' + min;
    }

    return (
      days[d.getDay()] + ' - ' + d.getHours() + 'h' + min + ' à ' + dend.getHours() + 'h' + minend
    );
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const getSmiley = (rate: number) => {
  switch (rate) {
    case 0:
      return '😡';
    case 1:
      return '😠';
    case 2:
      return '😐';
    case 3:
      return '🙂';
    case 4:
      return '😄';
    case 5:
      return '😍';
  }
};

const getRating = (rates?: { rate: number; comment?: string }[]) => {
  if (Array.isArray(rates) && rates.length) {
    const smiley = getSmiley(rates[0].rate);
    return smiley;
  }
  return '';
};

export const Scores = ({ userId }: { userId: number }) => {
  const { data: scores } = useScores(userId);
  const { data: user } = useUser(userId);
  const { data: planning } = usePlanning(userId);
  const { data: scoreboard } = useRanking();
  let navigate = useNavigate();
  function onClick(
    id: number,
    title: string,
    type: 'loup' | 'petanque' | 'jeu long' | 'jeu court' | 'hitster'
  ) {
    console.log('type', type);

    if (type === 'hitster') {
      navigate('/hitster');
      return;
    }

    if (type === 'loup') {
      navigate('/loups');
      return;
    }

    if (type === 'petanque') {
      navigate('/petanque/?admin=' + user?.isAdmin);
    } else {
      navigate('/planning/' + id + '?userId=' + userId + '&admin=' + user?.isAdmin);
    }
  }

  const getScore = (
    data?: {
      score: number;
    }[]
  ) => {
    if (data?.length === 0) {
      return;
    }
    if (!data) {
      return;
    }
    return data[0].score;
  };

  const setRouteFct = () => {
    navigate('/ranking');
  };

  const setNotesFct = () => {
    navigate('/form');
  };

  const setFavoriteFct = () => {
    navigate('/favorite');
  };

  return (
    <motion.div
      className="ranking-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}>
      <p className="greeting-message">
        Bonjour {user?.name} {user?.isAdmin ? '(Chef de table)' : null}{' '}
        {user?.winner_year ? '🏆' : ''} ! 👋
      </p>
      <div className="notation" onClick={setFavoriteFct}>
        Désigner qui va gagner cette édition🏆
      </div>

      <div className="score-container">
        <div className="score-card">
          <p>Score</p>
          {scores ? (
            <p className="score-card-value">{scores.reduce((a, b) => a + b.score, 0)} pts</p>
          ) : (
            <p className="score-card-value">0 pts</p>
          )}
        </div>
        <div className="ranking-card" onClick={setRouteFct}>
          <p>Classement</p>
          {scoreboard ? (
            <p className="ranking-card-value">
              {scoreboard.find((p) => p.player_id === userId)?.rank}
            </p>
          ) : (
            <p className="ranking-card-value">1</p>
          )}
        </div>
      </div>

      <div className="planning">
        <p className="planning-section-title">Votre Planning 🗓️</p>
        {planning ? (
          <div>
            <div>
              {planning
                .sort((a, b) =>
                  new Date(a.planning_dates.start).getTime() >
                  new Date(b.planning_dates.start).getTime()
                    ? 1
                    : -1
                )
                .map((item, idx) => (
                  <div key={idx}>
                    <p className="date">
                      {transformDate(item.planning_dates.start, item.planning_dates.end)}
                    </p>

                    <div
                      className={
                        item.games.type === 'loup'
                          ? 'loup planning-item'
                          : item.games.type === 'petanque'
                          ? 'petanque planning-item'
                          : 'court planning-item'
                      }
                      onClick={() => onClick(item.id, item.games.title, item.games.type)}>
                      {item.games.type === 'loup' ? (
                        <img src={item.games.url} alt={item.games.title} />
                      ) : null}
                      <p>
                        <b>{idx + 1}.</b>
                        {item.games.title}
                        {getRating(item.games.ratings)}
                      </p>
                      {item.admin_id ? (
                        <p className="adminName">
                          {item.admin_id.name} - {item.games.room}
                        </p>
                      ) : null}
                      {getScore(item.scores) ? (
                        <p className={item.game_id < 7 ? 'score-b' : 'score-b'}>
                          {getScore(item.scores)} pts
                        </p>
                      ) : (
                        <p className="score-b">0 pts</p>
                      )}
                    </div>
                  </div>
                ))}
            </div>
            <div className="notation" onClick={setNotesFct}>
              ⭐⭐ Donnez votre avis pour l'édition 2025
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </motion.div>
  );
};
