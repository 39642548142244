import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from './supabase';
// export function useUser(playerId: number) {
//   return useQuery(['user', playerId], async () => {
//     const { data } = await supabase
//       .from('users')
//       .select('name, isAdmin, winner_year')
//       .eq('id', playerId)
//       .limit(1)
//       .single();
//     return data;
//   });
// }
export function usePetanquePlanning() {
  return useQuery(['planning_petanque'], async () => {
    const { data } = await supabase
      .from('planning_petanque')
      .select('id, team1_id, team2_id, round, score_team1, score_team2');
    return data;
  });
}

export function usePetanquePlanningV2() {
  return useQuery(['planning_petanque_v2'], async () => {
    const { data } = await supabase
      .from('planning_petanque_v2')
      .select('id, team1_id, team2_id, round, score_team1, score_team2, group');
    return data;
  });
}

export function useUpdateMatchScore() {
  const queryClient = useQueryClient();
  return useMutation(
    ['update_match_score'],
    async (variables: { matchId: number; score1: number; score2: number }) => {
      console.log('update_match_score', variables);
      await supabase.from('planning_petanque').upsert({
        score_team1: variables.score1,
        score_team2: variables.score2,
        id: variables.matchId
      });
      return;
    },
    {
      // After the mutation, invalidate the 'planning_petanque' query to refetch data
      onSuccess: () => {
        queryClient.invalidateQueries(['planning_petanque']);
      }
    }
  );
}

export function useUpdateMatchScoreV2() {
  const queryClient = useQueryClient();
  return useMutation(
    ['update_match_score_v2'],
    async (variables: { matchId: number; score1: number; score2: number }) => {
      console.log('update_match_score_v2', variables);
      await supabase.from('planning_petanque_v2').upsert({
        score_team1: variables.score1,
        score_team2: variables.score2,
        id: variables.matchId
      });
      return;
    },
    {
      // After the mutation, invalidate the 'planning_petanque' query to refetch data
      onSuccess: () => {
        queryClient.invalidateQueries(['planning_petanque_v2']);
      }
    }
  );
}
