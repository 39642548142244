import { useMutation, useQuery } from '@tanstack/react-query';
import { supabase } from './supabase';

export function useUpdateRating() {
  return useMutation(
    ['updateRating'],
    async (variables: {
      input: {
        patch: {
          rate: number;
          user_id: number;
          game_id: number;
        };
        id: number;
      };
    }) => {
      await supabase.from('ratings').upsert({
        rate: variables.input.patch.rate,
        id: variables.input.id,
        user_id: variables.input.patch.user_id,
        game_id: variables.input.patch.game_id
      });
      return;
    }
  );
}

export function useRatings(playerId: number, gameId: number) {
  return useQuery(['rating', playerId], async () => {
    const { data } = await supabase
      .from('ratings')
      .select('rate, comment, id')
      .eq('user_id', playerId)
      .eq('game_id', gameId)
      .limit(1)
      .single();
    return data;
  });
}
