import { useUsers } from '../api/users';
import { User } from './user';
import { Loader } from './loader';
import { motion } from 'framer-motion';

export const ListUser = ({ setUserId }: { setUserId: any }) => {
  const logo = require('../logo.png');
  const { data } = useUsers();
  return data ? (
    <motion.div
      className="list-user-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}>
      <div className="logo-container">
        <img src={logo} alt="logo" className="logo" />
        <h1 className="tournament-title">Weekend jeux 2024</h1>
      </div>
      <h2 className="player-selection-title">Choisissez votre profil</h2>
      <div className="player-grid">
        {data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((user) => (
            <User
              key={user.id}
              admin={user.isAdmin}
              name={user.name}
              id={user.id}
              setUserId={setUserId}
              winnerYear={user.winner_year}
            />
          ))}
      </div>
    </motion.div>
  ) : (
    <Loader />
  );
};
