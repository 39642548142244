import { useQuery } from '@tanstack/react-query';
import { supabase } from './supabase';
export function usePlanningById(id: number) {
  return useQuery(['planning_id', id], async () => {
    const { data } = await supabase
      .from('planning')
      .select('round, game_id, id, games (url, title, id)')
      .eq('id', id)
      .limit(1, { referencedTable: 'games' })
      .single();

    return data as unknown as {
      game_id: number;
      id: number;
      round: number;
      games: {
        url: string;
        title: string;
        id: number;
      };
    };
  });
}
