import { useRanking } from '../api/ranking';
import { motion } from 'framer-motion';
import { Loader } from './loader';
import { useUser, useUsers } from '../api/users';
import { useLocalStorage } from 'react-use';
import { usePetanquePlanningV2, useUpdateMatchScoreV2 } from '../api/petanque';
import { useState } from 'react';

interface Player {
  id: number;
  name: string;
  isAdmin: boolean;
  winner_year: string;
  petanque_team: number;
}

const teams = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

const dates = [
  'Vendredi 11h00 - 12h00',
  'Vendredi 15h00 - 16h00',
  'Vendredi 16h00 - 17h00',
  'Samedi 11h00 - 12h00',
  'Samedi 15h00 - 16h00'
];

const getDate = (round: number) => {
  return dates[round - 1];
};

const getTeamName = (teamId: number) => {
  return teams[teamId - 1];
};

const getTeamsPlayers = (users: Player[] | undefined, teamId: number, myTeamId?: number) => {
  if (teamId === myTeamId) {
    return 'Vous';
  }

  return `${getTeamName(teamId)}. ${users
    ?.filter((u) => u.petanque_team === teamId)
    .map((u) => u.name)
    .join(', ')}`;
};

const getAllScores = (
  planning:
    | {
        id: any;
        team1_id: any;
        team2_id: any;
        round: any;
        score_team1: any;
        score_team2: any;
        group: any;
      }[]
    | undefined
    | null,
  group: any | null
) => {
  if (!planning) {
    return null;
  }
  const scores: Record<number, number> = {};
  planning
    .filter((p) => (group == null ? true : p.group === group))
    .forEach((p) => {
      if (!scores[p.team1_id]) {
        scores[p.team1_id] = 0;
      }
      if (!scores[p.team2_id]) {
        scores[p.team2_id] = 0;
      }
      scores[p.team1_id] += p.score_team1;
      scores[p.team2_id] += p.score_team2;
      if (p.score_team1 > p.score_team2) {
        scores[p.team1_id] += 2;
      } else if (p.score_team2 > p.score_team1) {
        scores[p.team2_id] += 2;
      }
    });
  return scores;
};

const getRanking = (scores: Record<number, number> | null) => {
  if (!scores) {
    return null;
  }
  return Object.entries(scores)
    .sort((a, b) => b[1] - a[1])
    .reduce((acc, [player, score], index, array) => {
      if (index === 0 || score !== array[index - 1][1]) {
        // New rank for different score
        acc.push([index + 1, player, score]);
      } else {
        // Same rank for equal score
        acc.push([acc[acc.length - 1][0], player, score]);
      }
      return acc;
    }, [] as [number, string, number][]);
};

export const PetanquePage = () => {
  // const [userId] = useLocalStorage('userId', -1);
  const { data: users } = useUsers();
  const { data } = useRanking();
  const [userId] = useLocalStorage('userId', -1);
  const { data: user } = useUser(userId ?? -1);

  const { data: planning, refetch } = usePetanquePlanningV2();
  const [editingMatch, setEditingMatch] = useState<number | null>(null);
  const [score1, setScore1] = useState(0);
  const [score2, setScore2] = useState(0);

  const [isToggled, setIsToggled] = useState(false);

  // Function to handle the button click and toggle the state
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const { mutate: updateMatchScoreV2 } = useUpdateMatchScoreV2();

  const handleEditScore = (matchId: number, _score1: number, _score2: number) => {
    if (user?.isAdmin && editingMatch == null) {
      console.log('called', matchId);
      setEditingMatch(matchId);
      setScore1(_score1 ?? score1 ?? 0);
      setScore2(_score2 ?? score2 ?? 0);
    }
  };

  const handleSaveScore = async (matchId: number) => {
    console.log('save score', matchId, score1, score2);
    try {
      await updateMatchScoreV2({ matchId, score1, score2 });
      await refetch();
      setEditingMatch(null);

      // Refresh the planning data here
      // This depends on how you're fetching the data, you might need to call a refetch function
    } catch (error) {
      console.error('Failed to update score:', error);
      // Handle error (show a message to the user, etc.)
    }
  };

  const myTeam = users?.filter(
    (u) => users?.find((u) => u.id === userId)?.petanque_team === u.petanque_team
  );
  const scores = getAllScores(planning, null);
  const scoresGroup1 = getAllScores(planning, 1);
  const scoresGroup2 = getAllScores(planning, 2);

  const score = scores?.[user?.petanque_team];

  //const ranks = getRanking(scores);

  const rankingGroup1 = getRanking(scoresGroup1);
  console.log('rankingGroup1', rankingGroup1);
  const rankingGroup2 = getRanking(scoresGroup2);

  const displayedPlanning =
    user?.isAdmin && isToggled
      ? planning?.sort((a, b) => {
          // First, sort by round
          if (a.round !== b.round) {
            return a.round - b.round;
          }
          // If rounds are the same, sort by id
          return a.id - b.id;
        })
      : planning
          ?.filter((p) => p.team1_id === user?.petanque_team || p.team2_id === user?.petanque_team)
          ?.sort((a, b) => {
            // First, sort by round
            if (a.round !== b.round) {
              return a.round - b.round;
            }
            // If rounds are the same, sort by id
            return a.id - b.id;
          });

  const getMissingRound = (planning: any) => {
    const rounds = planning.map((p: any) => p.round);
    console.log('rounds', rounds);
    let difference = [1, 2, 3, 4, 5].filter((x) => !rounds.includes(x));
    //const missingRounds = dates.map((d, i) => i).filter((r) => !rounds.includes(r));
    console.log('difference', difference);
    return difference[0];
  };

  if (!user?.isAdmin || (user?.isAdmin && !isToggled)) {
    const missingRound = getMissingRound(displayedPlanning ?? []);

    displayedPlanning?.push({
      id: 999,
      team1_id: null,
      team2_id: null,
      round: missingRound,
      score_team1: null,
      score_team2: null,
      group: user?.petanque_team <= 5 ? 1 : 2
    });

    displayedPlanning?.sort((a, b) => {
      // First, sort by round
      if (a.round !== b.round) {
        return a.round - b.round;
      }
      // If rounds are the same, sort by id
      return a.id - b.id;
    });
  }
  return data ? (
    <motion.div
      className="petanque-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}>
      <h2>Pétanque</h2>

      <section className="team-info">
        <h3>
          Mon équipe : {getTeamName(user?.petanque_team ?? 0)} - Mon score : {score}
        </h3>
        <div className="team-members">
          {myTeam?.map((player) => (
            <div key={player.id} className={`player ${player.id === userId ? 'me' : ''}`}>
              {player.name}
            </div>
          ))}
        </div>
      </section>

      <section className="planning">
        <h3>{user?.isAdmin ? 'Toutes les rencontres' : 'Mes rencontres'}</h3>

        {user?.isAdmin ? (
          <div>
            <div>Vue Admin</div>
            <button
              onClick={handleToggle}
              style={{
                padding: '10px 20px',
                backgroundColor: isToggled ? '#2ecc71' : 'red',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}>
              {isToggled ? 'ON' : 'OFF'}
            </button>
          </div>
        ) : null}
        <ul className="match-list">
          {displayedPlanning?.map((p) => (
            <li
              key={p.id}
              className="match"
              onClick={() => handleEditScore(p.id, p.score_team1, p.score_team2)}>
              <div className="match-date">{getDate(p.round)}</div>
              {p.id === 999 ? (
                <div>Repos</div>
              ) : (
                <div className="match-teams">
                  <div className="left-team">
                    <p className={p.score_team1 > p.score_team2 ? 'winner ' : ''}>
                      {getTeamsPlayers(users, p.team1_id ?? 0, user?.petanque_team)}
                    </p>

                    {user?.isAdmin && editingMatch === p.id ? (
                      <div className="match-score-edit">
                        <div className="match-score-edit-input">
                          <button
                            className="match-score-edit-input-btn match-score-edit-input-btn-minus"
                            onClick={() => setScore1(Math.max(0, score1 - 1))}>
                            -
                          </button>
                          <div className="match-score-edit-input-value">{score1}</div>
                          <button
                            className="match-score-edit-input-btn match-score-edit-input-btn-plus"
                            onClick={() => setScore1(Math.min(11, score1 + 1))}>
                            +
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="match-score">{p.score_team1}</div>
                    )}
                  </div>

                  <div className="right-team">
                    <p className={p.score_team2 > p.score_team1 ? 'winner' : ''}>
                      {getTeamsPlayers(users, p.team2_id ?? 0, user?.petanque_team)}
                    </p>

                    {user?.isAdmin && editingMatch === p.id ? (
                      <div className="match-score-edit">
                        <div className="match-score-edit-input">
                          <button
                            className="match-score-edit-input-btn match-score-edit-input-btn-minus"
                            onClick={() => setScore2(Math.max(0, score2 - 1))}>
                            -
                          </button>
                          <div className="match-score-edit-input-value">{score2}</div>
                          <button
                            className="match-score-edit-input-btn match-score-edit-input-btn-plus"
                            onClick={() => setScore2(Math.min(11, score2 + 1))}>
                            +
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="match-score">{p.score_team2}</div>
                    )}
                  </div>
                </div>
              )}
              {user?.isAdmin && editingMatch === p.id ? (
                <div className="match-score-save-btn" onClick={() => handleSaveScore(p.id)}>
                  Save
                </div>
              ) : null}
            </li>
          ))}
        </ul>
      </section>

      <section className="ranking">
        <h3>Poule 1</h3>
        <ol className="ranking-list">
          {rankingGroup1?.map((rank) => (
            <li key={rank[1]} className="ranking-item">
              <span className="rank">{rank[0]}.</span>
              <span className="team">{getTeamsPlayers(users, +rank[1])}</span>
              <span className="score">{rank[2]} pts</span>
            </li>
          ))}
        </ol>
      </section>

      <section className="ranking">
        <h3>Poule 2</h3>
        <ol className="ranking-list">
          {rankingGroup2?.map((rank) => (
            <li key={rank[1]} className="ranking-item">
              <span className="rank">{rank[0]}.</span>
              <span className="team">{getTeamsPlayers(users, +rank[1])}</span>
              <span className="score">{rank[2]} pts</span>
            </li>
          ))}
        </ol>
      </section>

      <section className="ranking">
        <h3>Demi-finale 1</h3>
        {rankingGroup1 && rankingGroup2 && (
          <div>
            <p className="team">
              {rankingGroup1[0][2] === 0 ? '-' : getTeamsPlayers(users, +rankingGroup1[0][1])}
            </p>
            <p>VS</p>
            <p className="team">
              {rankingGroup2[1][2] === 0 ? '-' : getTeamsPlayers(users, +rankingGroup2[1][1])}
            </p>
          </div>
        )}

        <h3>Demi-finale 2</h3>
        {rankingGroup1 && rankingGroup2 && (
          <div>
            <p className="team">
              {rankingGroup1[1][2] === 0 ? '-' : getTeamsPlayers(users, +rankingGroup1[1][1])}
            </p>
            <p>VS</p>
            <p className="team">
              {rankingGroup2[0][2] === 0 ? '-' : getTeamsPlayers(users, +rankingGroup2[0][1])}
            </p>
          </div>
        )}
      </section>
    </motion.div>
  ) : (
    <Loader />
  );
};
