import { useNavigate, useLocation } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { CalendarMonth, EmojiEvents, PowerSettingsNew, TableView } from '@mui/icons-material';

export const Navbar = () => {
  const [userId, setUserId, remove] = useLocalStorage('userId', -1);
  const location = useLocation();

  window.addEventListener('storage', () => {
    const userId = localStorage.getItem('userId') || '-1';
    setUserId(JSON.parse(userId));
  });

  const navigate = useNavigate();

  return typeof userId === 'number' && userId > -1 ? (
    <>
      <div className="navbar">
        <div
          className={`navbar-tab ${location.pathname === '/home' ? 'active-tab' : 'inactive-tab'}`}
          onClick={() => navigate('/home')}>
          <CalendarMonth />
        </div>
        <div
          className={`navbar-tab ${
            location.pathname === '/ranking' ? 'active-tab' : 'inactive-tab'
          }`}
          onClick={() => navigate('/ranking')}>
          <EmojiEvents />
        </div>
        {userId && userId === 12 ? (
          <div
            className={`navbar-tab ${
              location.pathname.startsWith('/admin') ? 'active-tab' : 'inactive-tab'
            }`}
            onClick={() => {
              navigate('/admin');
            }}>
            <TableView />
          </div>
        ) : (
          <></>
        )}
        <div
          className="navbar-tab"
          onClick={() => {
            navigate('/users');
            remove();
          }}>
          <PowerSettingsNew />
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
