import { useQuery } from '@tanstack/react-query';
import { supabase } from './supabase';
export function useUser(playerId: number) {
  return useQuery(['user', playerId], async () => {
    const { data } = await supabase
      .from('users')
      .select('name, isAdmin, winner_year, petanque_team')
      .eq('id', playerId)
      .limit(1)
      .single();
    return data;
  });
}
export function useUsers() {
  return useQuery(['users'], async () => {
    const { data } = await supabase
      .from('users')
      .select('id, name, isAdmin, winner_year, petanque_team');
    return data as {
      id: number;
      name: string;
      isAdmin: boolean;
      winner_year: string;
      petanque_team: number;
    }[];
  });
}
