import { useLocalStorage } from 'react-use';
import { motion } from 'framer-motion';
import { Loader } from './loader';
import { useUsers } from '../api/users';
import { supabase } from '../api/supabase';
import { useEffect, useState } from 'react';

export const FavoritePage = () => {
  const { data: users } = useUsers();
  const [selectedFavorite, setSelectedFavorite] = useState<number | null>(null);
  const [userId] = useLocalStorage('userId', -1);
  useEffect(() => {
    const fetchFavorite = async () => {
      const { data, error } = await supabase
        .from('user_favorite')
        .select('user_vote_id')
        .eq('user_id', userId)
        .single();
      if (error) {
        console.error('Error fetching favorite:', error);
      } else if (data) {
        setSelectedFavorite(data.user_vote_id);
      }
    };

    fetchFavorite();
  }, [userId]);

  const handleFavoriteSelection = async (voteId: number) => {
    setSelectedFavorite(voteId);
    const { error } = await supabase
      .from('user_favorite')
      .upsert({ user_id: userId, user_vote_id: voteId });
    if (error) {
      console.error('Error updating favorite:', error);
    }
  };
  return users ? (
    <motion.div
      className="favorite-page"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, y: -20, transition: { duration: 0.5 } }}>
      <div className="favorite-page-header">
        <h1 className="favorite-title">Qui va gagner le WEJ 2024 ?</h1>
        <p className="favorite-subtitle">Uniquement possible jusqu'au premier soir</p>
      </div>
      <div className="user-grid">
        {users.map((user) => (
          <div
            key={user.id}
            className={`user-item ${selectedFavorite === user.id ? 'selected' : ''}`}
            onClick={() => handleFavoriteSelection(user.id)}>
            <p className="user-name">{user.name}</p>
          </div>
        ))}
      </div>
    </motion.div>
  ) : (
    <Loader />
  );
};
