import { useQuery } from '@tanstack/react-query';
import { supabase } from './supabase';

export function useRanking() {
  return useQuery(['ranking'], async () => {
    const { data } = await supabase
      .from('scoreboard')
      .select('rank, total_score, player_id, player_name')
      .order('rank');
    return data as unknown as {
      rank: number;
      total_score: number;
      player_id: number;
      player_name: string;
    }[];
  });
}
