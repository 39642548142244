import { useQuery } from '@tanstack/react-query';
import { supabase } from './supabase';
export function usePlanning(playerId: number) {
  return useQuery(['planning', playerId], async () => {
    const { data: planning } = await supabase
      .from('planning')
      .select(
        '*, admin_id (name), games (title, url, room, type, ratings(rate, comment) ), planning_dates(start,end), scores (score)'
      )
      .eq('user_id', playerId);

    planning?.push({
      game_id: 13,
      round: -1,
      id: 'petanque',
      games: {
        room: 'Extérieur',
        title: 'Pétanque',
        type: 'petanque'
      },
      planning_dates: {
        start: '2024-11-01T10:00:43+00:00',
        end: '2024-11-01T11:00:43+00:00',
        url: 'https://ldusuwqksjxyltpbwxfm.supabase.co/storage/v1/object/public/images/petanque.jpg'
      }
    });
    planning?.push({
      game_id: 13,
      round: -1,
      id: 'petanque',
      games: {
        room: 'Extérieur',
        title: 'Pétanque',
        type: 'petanque',
        url: 'https://ldusuwqksjxyltpbwxfm.supabase.co/storage/v1/object/public/images/petanque.jpg'
      },
      planning_dates: {
        start: '2024-11-01T14:00:43+00:00',
        end: '2024-11-01T16:00:43+00:00'
      }
    });
    planning?.push({
      game_id: 13,
      round: -1,
      id: 'petanque',
      games: {
        room: 'Extérieur',
        title: 'Pétanque',
        type: 'petanque',
        url: 'https://ldusuwqksjxyltpbwxfm.supabase.co/storage/v1/object/public/images/petanque.jpg'
      },
      planning_dates: {
        start: '2024-11-02T10:00:43+00:00',
        end: '2024-11-02T11:00:43+00:00'
      }
    });
    planning?.push({
      game_id: 13,
      round: -1,
      id: 'petanque',
      games: {
        room: 'Extérieur',
        title: 'Pétanque',
        type: 'petanque'
      },
      planning_dates: {
        start: '2024-11-02T14:00:43+00:00',
        end: '2024-11-02T16:00:43+00:00'
      }
    });

    planning?.push({
      game_id: 14,
      round: -1,
      id: 'loup',
      games: {
        room: 'Réception',
        title: 'Loups Garous de Thiercelieux',
        type: 'loup',
        url: 'https://ldusuwqksjxyltpbwxfm.supabase.co/storage/v1/object/public/images/loups.jpg'
      },
      planning_dates: {
        start: '2024-10-31T19:30:43+00:00'
      }
    });
    planning?.push({
      game_id: 14,
      round: -1,
      id: 'loup',
      games: {
        room: 'Réception',
        title: 'Loups Garous de Thiercelieux',
        type: 'loup',
        url: 'https://ldusuwqksjxyltpbwxfm.supabase.co/storage/v1/object/public/images/loups.jpg'
      },
      planning_dates: {
        start: '2024-11-01T19:30:43+00:00'
      }
    });
    planning?.push({
      game_id: 14,
      round: -1,
      id: 'hitster',
      games: {
        room: 'Réception',
        title: 'Hitster',
        type: 'hitster',
        url: 'https://ldusuwqksjxyltpbwxfm.supabase.co/storage/v1/object/public/images/hitser.jpg'
      },
      planning_dates: {
        start: '2024-11-02T19:30:43+00:00'
      }
    });

    return planning as {
      game_id: number;
      admin_id: { name: string };
      round: number;
      id: number;
      games: {
        room: string;
        title: string;
        url: string;
        type: 'jeu long' | 'jeu court' | 'petanque' | 'loup' | 'hitster';
        ratings: { rate: number; comment?: string }[];
      };
      planning_dates: {
        start: string;
        end: string;
      };
      scores: { score: number }[];
    }[];
  });
}
