import {
  List,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  BooleanInput
} from 'react-admin';

export const PlayerList = () => (
  <List perPage={50}>
    <Datagrid>
      <TextField source="name" />
      <BooleanField source="isAdmin" />
      <EditButton />
    </Datagrid>
  </List>
);

export const PlayerEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="name" />
      <BooleanInput source="isAdmin" />
    </SimpleForm>
  </Edit>
);

export const PlayerCreate = (props) => (
  <Create title="Ajouter un joueur" {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
