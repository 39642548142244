import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import { ListUser } from './components/list-user';
import { useLocalStorage } from 'react-use';
//import { Scores } from './router/scores';
import { usePlanningById } from './api/planning_id';
import { GameData } from './GameData';
import { ProtectedRoute } from './router/private';
import { RankingPage } from './components/ranking-page';
import { PetanquePage } from './components/petanque-page';
import { HitsterPage } from './components/hitster-page';
import { FavoritePage } from './components/favorite-page';
import { FormPage } from './components/form-page';
import { getSmiley } from './router/scores';
import { useRatings, useUpdateRating } from './api/rating';
import { AdminPanel } from './components/admin-panel';
import { Navbar } from './router/navbar';
import { AnimatePresence, motion } from 'framer-motion';
// import { supabase } from './api/supabase';
// import { useEffect, useState } from 'react';
import { LoupsPage } from './components/loups-page';

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <div className="modal">
          <link
            rel="stylesheet"
            href="https://unicons.iconscout.com/release/v3.0.6/css/solid.css"
          />
          <link rel="stylesheet" href="css/style.css" />
          <div className="main-container">
            <AnimatePresence>
              <Routes>
                <Route path="home" element={<Home />} />
                <Route path="planning">
                  <Route path=":id" element={<Game />} />
                </Route>
                <Route path="form" element={<FormPage />} />
                <Route path="petanque" element={<PetanquePage />} />
                <Route path="hitster" element={<HitsterPage />} />
                <Route path="loups" element={<LoupsPage />} />
                <Route path="favorite" element={<FavoritePage />} />
                <Route path="ranking" element={<RankingPage />} />
                <Route path="users" element={<Users />} />
                <Route path="*" element={<NoMatch />} />
                <Route path="admin/*" element={<AdminPanel />} />
              </Routes>
            </AnimatePresence>
          </div>
          <Navbar />
        </div>
      </div>
    </QueryClientProvider>
  );
}
export default App;

const Home = () => {
  return <ProtectedRoute />;
  // return <Navbar />;
  // const [userId] = useLocalStorage('userId', undefined);
  // const location = useLocation();
  // if (typeof userId !== 'number') {
  //   return <Navigate to="/users" replace state={{ from: location }} />;
  // }
  // return (
  //   <>
  //     <Scores userId={userId} />
  //   </>
  // );
};

const Users = () => {
  const [userId, setUserId] = useLocalStorage('userId', undefined);
  const location = useLocation();
  if (typeof userId === 'number') {
    return <Navigate to="/home" replace state={{ from: location }} />;
  }

  return <ListUser setUserId={setUserId} />;
};

const Ratings = ({ userId, gameId }: { userId: any; gameId: any }) => {
  const { data: dataRating, refetch } = useRatings(userId as any, gameId);
  const rate = dataRating?.rate;

  const update = useUpdateRating();

  const setRate = async (rate: number) => {
    await update.mutateAsync({
      input: {
        patch: {
          rate,
          user_id: userId,
          game_id: gameId
        },
        id: dataRating?.id as any
      }
    });
    refetch();
  };

  return (
    <>
      <h2 style={{ marginTop: 32, marginBottom: 32 }}>Donner votre avis sur le jeu</h2>
      <button className="btn-rate" onClick={() => setRate(0)} data-active={rate === 0}>
        {getSmiley(0)}
      </button>
      <button className="btn-rate" onClick={() => setRate(1)} data-active={rate === 1}>
        {getSmiley(1)}
      </button>
      <button className="btn-rate" onClick={() => setRate(2)} data-active={rate === 2}>
        {getSmiley(2)}
      </button>
      <button className="btn-rate" onClick={() => setRate(3)} data-active={rate === 3}>
        {getSmiley(3)}
      </button>
      <button className="btn-rate" onClick={() => setRate(4)} data-active={rate === 4}>
        {getSmiley(4)}
      </button>
      <button className="btn-rate" onClick={() => setRate(5)} data-active={rate === 5}>
        {getSmiley(5)}
      </button>
    </>
  );
};

// const invokeSupabaseFunction = async (gameName: string) => {
//   try {
//     const { data, error } = await supabase.functions.invoke('openai', {
//       body: { query: gameName }
//     });

//     if (error) {
//       console.error("Erreur lors de l'invocation de la fonction Supabase:", error);
//       return null;
//     }
//     return data.replace('```html\n', '').replace('```', '');
//   } catch (err) {
//     console.error('Erreur inattendue:', err);
//     return null;
//   }
// };

const Game = () => {
  const tmp = useParams();
  const loc = useLocation();
  const { data } = usePlanningById(tmp.id as any);
  const [userId] = useLocalStorage('userId', undefined);
  const admin = loc.search.includes('admin=true');

  // const [loading, setLoading] = useState(false);
  // const [rules, setRules] = useState<string | null>(null);

  // useEffect(() => {
  //   const fetchRules = async () => {
  //     if (data?.game_id) {
  //       setLoading(true);
  //       const result = await invokeSupabaseFunction(data.games.title);
  //       setRules(result);
  //       setLoading(false);
  //     }
  //   };
  //   fetchRules();
  // }, [data?.game_id, data?.games.title]);

  return (
    <motion.div
      className="game-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}>
      <h2>{admin ? 'Résultats' : 'Scores'}</h2>
      <span className="scores-game-title">{data?.games.title}</span>
      {data ? <GameData {...data} admin={admin} round={data.round} /> : undefined}
      {data?.game_id ? <Ratings userId={userId} gameId={data?.game_id} /> : null}

      {/* {data?.game_id ? (
        <>
          <h2 style={{ marginTop: 32, marginBottom: 32 }}>Règles</h2>
          {loading || !rules ? (
            <div className="loader">Chargement...</div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: rules }} />
          )}
        </>
      ) : null} */}
    </motion.div>
  );
};

const NoMatch = () => {
  const location = useLocation();
  return <Navigate to="/home" replace state={{ from: location }} />;
};
