export const Loader = () => {
  return (
    <div className="loader">
      <p>
        Chargement
        <span className="dot-one"> .</span>
        <span className="dot-two"> .</span>
        <span className="dot-three"> .</span>
      </p>
    </div>
  );
};
