import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { supabase } from '../api/supabase';
import { useLocalStorage } from 'react-use';

export const FormPage = () => {
  const [userId] = useLocalStorage('userId', -1);
  const [games, setGames] = useState<
    { title: string; image: string; id: string; description: string; year: string }[]
  >([]);
  const [selectedGames, setSelectedGames] = useState<string[]>([]);

  useEffect(() => {
    const fetchGames = async () => {
      const { data, error } = await supabase
        .from('best_games')
        .select('title, image, id, description, year');
      if (error) {
        console.error('Error fetching games:', error);
      } else {
        setGames(data);
      }
    };

    const fetchSelectedGames = async () => {
      const { data, error } = await supabase
        .from('users_best_games_selected')
        .select('best_games_id')
        .eq('user_id', userId);
      if (error) {
        console.error('Error fetching selected games:', error);
      } else {
        setSelectedGames(data.map((game: { best_games_id: string }) => game.best_games_id));
      }
    };

    fetchGames();
    fetchSelectedGames();
  }, [userId]);

  const updateSelectedGamesInDB = async (selectedGames: string[]) => {
    await supabase.from('users_best_games_selected').delete().eq('user_id', userId);
    const { error } = await supabase
      .from('users_best_games_selected')
      .upsert(selectedGames.map((best_games_id) => ({ user_id: userId, best_games_id })));
    if (error) {
      console.error('Error updating selected games:', error);
    }
  };

  const handleGameSelection = (game_id: string) => {
    setSelectedGames((prevSelectedGames) => {
      if (prevSelectedGames.includes(game_id)) {
        const updatedGames = prevSelectedGames.filter((game) => game !== game_id);
        updateSelectedGamesInDB(updatedGames);
        return updatedGames;
      } else if (prevSelectedGames.length < 12) {
        const updatedGames = [...prevSelectedGames, game_id];
        updateSelectedGamesInDB(updatedGames);
        return updatedGames;
      } else {
        return prevSelectedGames;
      }
    });
  };

  return (
    <motion.div
      className="game-selection-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}>
      <div className="game-selection-header">
        <h1 className="game-selection-title">
          Sélectionnez les 12 jeux que vous avez adoré, parmi les 28 qui ont été le plus appréciés
          entre 2016 et 2023 !
        </h1>
        <p className="game-selection-subtitle">{selectedGames.length} / 12 jeux sélectionnés</p>
      </div>
      <div className="game-grid">
        {games
          .sort((a, b) => a.year.localeCompare(b.year))
          .map((game) => (
            <motion.div
              key={game.id}
              className={`game-card ${selectedGames.includes(game.id) ? 'selected' : ''}`}
              onClick={() => handleGameSelection(game.id)}
              whileTap={{ scale: 0.95 }}>
              <div className="game-image-container">
                <img src={game.image} alt={game.title} className="game-image" />
                {selectedGames.includes(game.id) && (
                  <div className="selected-overlay">
                    <span className="checkmark">✓</span>
                  </div>
                )}
              </div>
              <div className="game-info">
                <h2 className="game-title">{game.title}</h2>
                <p className="game-year">#{game.year}</p>
                <p className="game-description">{game.description}</p>
              </div>
            </motion.div>
          ))}
      </div>
    </motion.div>
  );
};
