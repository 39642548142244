import {
  List,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Datagrid,
  TextField,
  EditButton
} from 'react-admin';

export const GameList = () => (
  <List perPage={50}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <EditButton />
    </Datagrid>
  </List>
);

export const GameEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="title" />
    </SimpleForm>
  </Edit>
);

export const GameCreate = (props) => (
  <Create title="Ajouter un jeu" {...props}>
    <SimpleForm>
      <TextInput source="title" />
    </SimpleForm>
  </Create>
);
