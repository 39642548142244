import { useEffect, useState } from 'react';
import { Admin, Resource, nanoLightTheme, nanoDarkTheme } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import pgDataProvider from 'ra-postgraphile';
import { GameList, GameEdit, GameCreate } from './admin-panel-games';
import { PlayerList, PlayerEdit, PlayerCreate } from './admin-panel-players';
import { PlanningEdit, PlanningList } from './admin-panel-plannings';
import { Group, CalendarMonth, SportsEsports } from '@mui/icons-material';

export const AdminPanel = () => {
  const [dataProvider, setDataProvider] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const client = new ApolloClient({
      uri: 'https://wej-backend-sdauzsikga-ew.a.run.app/graphql',
      //uri: 'http://localhost:8080/graphql',
      cache: new InMemoryCache()
    });
    (async () => {
      const dataProvider = await pgDataProvider(client);
      setDataProvider(() => dataProvider);
    })();
  }, []);

  return (
    dataProvider && (
      <>
        <Admin
          basename="/admin"
          dataProvider={dataProvider}
          lightTheme={nanoLightTheme}
          nanoDarkTheme={nanoDarkTheme}
        >
          <Resource
            name="games"
            recordRepresentation="title"
            options={{ label: 'Jeux' }}
            list={GameList}
            edit={GameEdit}
            create={GameCreate}
            icon={SportsEsports}
            sort={{ field: 'title', order: 'ASC' }}
          />
          <Resource
            name="players"
            recordRepresentation="name"
            options={{ label: 'Joueurs' }}
            list={PlayerList}
            edit={PlayerEdit}
            create={PlayerCreate}
            icon={Group}
            sort={{ field: 'name', order: 'ASC' }}
          />
          <Resource
            name="planningV2S"
            options={{ label: 'Planning' }}
            list={PlanningList}
            edit={PlanningEdit}
            icon={CalendarMonth}
            sort={{ field: 'date', order: 'ASC' }}
          />
        </Admin>
        <div className="navbar" onClick={() => navigate('/home')}>
          <div className="navbar-tab">Retour</div>
        </div>
      </>
    )
  );
};
