import {
  List,
  Edit,
  SimpleForm,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  ReferenceInput,
  DateField,
  SelectInput,
  TextInput
} from 'react-admin';

const optionRenderer = (x) => {
  const options = { weekday: 'long', hour: 'numeric' };
  const date = new Date(x.date);
  return date.toLocaleDateString('fr-FR', options);
};

const planningFilters = [
  <ReferenceInput source="round" reference="planningDates" alwaysOn>
    <SelectInput source="date" label="Créneau" optionText={optionRenderer} />
  </ReferenceInput>,
  <ReferenceInput source="gameId" reference="games" alwaysOn>
    <SelectInput label="Jeu" />
  </ReferenceInput>,
  <ReferenceInput source="playerId" label="Joueur" reference="players" alwaysOn>
    <SelectInput label="Joueur" />
  </ReferenceInput>,
  <TextInput source="adminName" label="Chef de table" alwaysOn />
];

export const PlanningList = () => (
  <List filters={planningFilters} perPage={50}>
    <Datagrid>
      <ReferenceField source="round" reference="planningDates" label="Créneau">
        <DateField
          source="date"
          options={{
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric'
          }}
          showTime
        />
      </ReferenceField>
      {/* <TextField source="round" /> */}
      <ReferenceField source="gameId" reference="games" label="Jeu" />
      <ReferenceField source="playerId" reference="players" label="Joueur" />
      <TextField source="adminName" label="Chef de table" />
      <EditButton />
    </Datagrid>
  </List>
);

export const PlanningEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="round" reference="planningDates" alwaysOn>
        {/* <DateTimeInput source="date" label="Créneau" alwaysOn /> */}
        <SelectInput source="date" label="Créneau" optionText={optionRenderer} />
      </ReferenceInput>
      <ReferenceInput source="gameId" label="Jeu" reference="games">
        <SelectInput source="title" />
      </ReferenceInput>
      <ReferenceInput source="playerId" label="Joueur" reference="players">
        <SelectInput source="name" />
      </ReferenceInput>
      <TextInput source="adminName" label="Chef de table" />
    </SimpleForm>
  </Edit>
);

export const PlanningDateList = () => (
  <List>
    <Datagrid>
      <TextField source="round" />
      <DateField
        source="date"
        showTime
        options={{
          weekday: 'long',
          hour: 'numeric',
          minute: 'numeric'
        }}
      />
      <EditButton />
    </Datagrid>
  </List>
);
