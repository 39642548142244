import { IPlanning, useMutationScore, useRemoveScore, useScore } from './api/score';
import { Loader } from './components/loader';

const getScore = (scores: IPlanning['scores']) => {
  if (!scores) {
    return undefined;
  }
  return scores[0]?.score;
};
export const GameData = ({
  round,
  games,
  admin
}: {
  admin: boolean;
  round: number;
  games: {
    id: number;
    url: string;
    threevsthree?: boolean;
    title: string;
  };
}) => {
  const { data, refetch, isLoading } = useScore(round, games.id);
  const mutation = useMutationScore();
  const remove = useRemoveScore();
  async function onSet(d: IPlanning, score: number) {
    if (admin) {
      if (getScore(d.scores) === score && d.scores?.[0]?.id) {
        await remove.mutateAsync({ id: d.scores?.[0]?.id });
      } else {
        await mutation.mutateAsync({
          input: {
            score: {
              gameId: games.id,
              playerId: d.user_id.id,
              planningId: d.id,
              score
            }
          }
        });
      }
      refetch();
    }
  }

  if (isLoading) {
    return <Loader />;
  }
  return data ? (
    <>
      <img
        src={games.url}
        alt={games.title}
        height={120}
        style={{
          margin: '0 auto',
          marginTop: 16,
          marginBottom: 16,
          display: 'flex',
          objectFit: 'cover',
          borderRadius: 8
        }}
      />
      <ul className="scores-user">
        {data.map((d) => (
          <li key={d.user_id.id}>
            <p>{d.user_id.name}</p>
            <>
              <button
                className={`first ${getScore(d.scores) === 3 ? 'active' : ''}`}
                onClick={() => onSet(d, 3)}
                disabled={!admin}>
                1er
              </button>
              <button
                className={`second ${getScore(d.scores) === 2 ? 'active' : ''}`}
                onClick={() => onSet(d, 2)}
                disabled={!admin}>
                2nd
              </button>
              <button
                className={`third ${getScore(d.scores) === 1 ? 'active' : ''}`}
                onClick={() => onSet(d, 1)}
                disabled={!admin}>
                3ème
              </button>
            </>
            {/* )} */}
          </li>
        ))}
      </ul>
    </>
  ) : (
    <div />
  );
};
