import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from './supabase';

export function useScore(round: number, gameId: number) {
  return useQuery(['score', round, gameId], async () => {
    const { data } = await supabase
      .from('planning')
      .select('*, user_id(id, name), scores(id, score)')
      .eq('round', round)
      .eq('game_id', gameId);

    return data as IPlanning[];
  });
}

export interface IPlanning {
  id: number;
  game_id: number;
  round: number;
  scores?: {
    id: number;
    score: number;
  }[];
  user_id: {
    id: number;
    name: string;
  };
}
export function useMutationScore() {
  const queryClient = useQueryClient();
  return useMutation(
    ['score'],
    async (variables: {
      input: {
        score: { gameId: number; playerId: number; planningId: number; score: number };
      };
    }) => {
      await supabase.from('scores').upsert(
        {
          score: variables.input.score.score,
          user_id: variables.input.score.playerId,
          game_id: variables.input.score.gameId,
          planning_id: variables.input.score.planningId
        },
        { onConflict: 'user_id,game_id,planning_id' }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['score']);
      }
    }
  );
}

export function useRemoveScore() {
  return useMutation(['removeScore'], async (variables: { id: number }) => {
    await supabase.from('scores').delete().eq('id', variables.id);
  });
}
