import { motion } from 'framer-motion';
import { useLocalStorage } from 'react-use';

export const HitsterPage = () => {
  console.log('HitsterPage');
  const [userId] = useLocalStorage('userId', -1);
  const users = [
    {
      id: 2,
      name: 'Annie',
      team: 'A'
    },
    {
      id: 3,
      name: 'Audrey',
      team: 'D'
    },
    {
      id: 4,
      name: 'Axel',
      team: 'C'
    },
    {
      id: 5,
      name: 'Ayoub',
      team: 'C'
    },
    {
      id: 6,
      name: 'Benoit',
      team: 'C'
    },
    {
      id: 7,
      name: 'Bernard',
      team: 'D'
    },
    {
      id: 8,
      name: 'Catherine',
      team: 'B'
    },
    {
      id: 9,
      name: 'Cédric',
      team: 'D'
    },
    {
      id: 10,
      name: 'Chloe',
      team: 'D'
    },
    {
      id: 11,
      name: 'Clement',
      team: 'C'
    },
    {
      id: 12,
      name: 'Dominique',
      team: 'B'
    },
    {
      id: 13,
      name: 'Erwan',
      team: 'B'
    },
    {
      id: 14,
      name: 'Fanny',
      team: 'C'
    },
    {
      id: 15,
      name: 'Françoise',
      team: 'A'
    },
    {
      id: 16,
      name: 'Geoffrey',
      team: 'A'
    },
    {
      id: 17,
      name: 'Isabelle',
      team: 'B'
    },
    {
      id: 18,
      name: 'Jean-Louis',
      team: 'D'
    },
    {
      id: 19,
      name: 'Jo',
      team: 'C'
    },
    {
      id: 20,
      name: 'Keurcien',
      team: 'A'
    },
    {
      id: 21,
      name: 'Laure',
      team: 'B'
    },
    {
      id: 22,
      name: 'Léo',
      team: 'D'
    },
    {
      id: 23,
      name: 'Luc',
      team: 'D'
    },
    {
      id: 24,
      name: 'Luca',
      team: 'A'
    },
    {
      id: 25,
      name: 'Martine',
      team: 'C'
    },
    {
      id: 26,
      name: 'Timothée',
      team: 'A'
    },
    {
      id: 27,
      name: 'Matthieu',
      team: 'D'
    },
    {
      id: 28,
      name: 'Murielle',
      team: 'A'
    },
    {
      id: 29,
      name: 'Nadine',
      team: 'C'
    },
    {
      id: 30,
      name: 'Natalie',
      team: 'D'
    },
    {
      id: 31,
      name: 'Nathalie',
      team: 'D'
    },
    {
      id: 32,
      name: 'Olivia',
      team: 'C'
    },
    {
      id: 33,
      name: 'Patrick',
      team: 'B'
    },
    {
      id: 34,
      name: 'Selim',
      team: 'A'
    },
    {
      id: 35,
      name: 'Camille',
      team: 'A'
    },
    {
      id: 36,
      name: 'Sonia',
      team: 'C'
    },
    {
      id: 37,
      name: 'Sylvie',
      team: 'A'
    },
    {
      id: 38,
      name: 'Laure G',
      team: 'B'
    },
    {
      id: 39,
      name: 'Mathieu',
      team: 'B'
    },
    {
      id: 40,
      name: 'Titouan',
      team: 'B'
    },
    {
      id: 41,
      name: 'Virginie',
      team: 'B'
    }
  ];

  const myTeam = users?.filter((u) => users?.find((u) => u.id === userId)?.team === u.team);

  return (
    <motion.div
      className="hitster-page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}>
      <h2>Hitster</h2>

      <section className="hitster-team-info">
        <h3>Mon équipe : {myTeam[0].team}</h3>
        <div className="hitster-team-members-container">
          {myTeam?.map((player) => (
            <div key={player.id} className="hitster-team-member">
              {player.name}
            </div>
          ))}
        </div>
      </section>
    </motion.div>
  );
};
